require('./bootstrap');

// import 'slick-carousel/slick/slick';
import '@splidejs/splide/dist/js/splide'
import Splide from '@splidejs/splide';
let YaMapsShown = false;



$(document).ready(function () {

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('#back-to-top').fadeIn();
		} else {
			$('#back-to-top').fadeOut();
		}
	});

	$('#back-to-top').click(function () {
		$("html, body").animate({ scrollTop: 0 }, 600);
		return false;
	});

	$('.b-popup-banner .close').on('click', function (e) {
		e.preventDefault();
		close_popup();
	})

	$('.style-btn-zapis').on('click', function (e) {
		e.preventDefault();
		close_popup();
	})

	$("form.phone_ajax button").on('click', function (e) {
		e.preventDefault();
		$.ajax({
			url: '/api/dentistry/make-request',
			type: "POST",
			dataType: "JSON",
			data: $(e.target).parents('.phone_ajax').serialize(),
			success: function (response) {
				console.log(response);
				if (response.status == 'Success') {
					$(e.target).parents('.request').find('.result').addClass('active')
					// yaCounter57397360.reachGoal('forma');
					// alert('Мы получили Вашу заявку. Менеджер с Вами свяжется. Спасибо!');
				}
			},
			error: function (response) { // Данные не отправлены
				alert('Ошибка отправки');
			}
		});

		// alert('sdsdsd');
	});


});

document.addEventListener('DOMContentLoaded', function () {
	if ($(".splide.project_images").length) {
		new Splide('.splide.project_images', {
			type: 'slide',
			trimSpace: true,
			autoWidth: true,
			fixedHeight: 300,
			arrows: true,
			pagination: true,
			breakpoints: {
				767: {
					fixedHeight: 210,
				}
			}
		}).mount();
	}
	// "xs": 320px,
	// "sm": 576px,
	// "md": 768px,
	// "lg": 992px,
	// "xl": 1200px,
	// "xll": 1400px,
	// "xlll": 1700px,
	if ($(".splide.home").length) {
		new Splide('.splide.home', {
			type: 'slide',
			arrows: true,
			trimSpace: true,
			perPage: 3,
			pagination: false,
			autoplay: true,
			gap: "1em",
			breakpoints: {
				1700: {
					perPage: 3,
				},
				1400: {
					perPage: 3,
				},
				992: {
					perPage: 2,
				},
				576: {
					perPage: 1,
				},
			},
		}).mount();
	}
});

// $('.project-screenshot').slick({
// 	// slidesToScroll: 1,
// 	variableWidth: true,
// 	swipeToSlide: true,
// 	dots: true,
// 	arrows: true,
// 	infinite: false,
// });

function close_popup() {
	$(".b-popup-banner").css('visibility', function (i, visibility) {
		if (visibility === 'visible') {
			document.body.classList.remove("no-overflow");
			$('.b-popup-banner').find('.result').removeClass('active')
			return 'hidden';
		} else {

			document.body.classList.add("no-overflow");
			return 'visible';
		}
	});
}

$(function () {
	//BEGIN
	$(".burger-header-mobile").on("click", function (e) {

		e.preventDefault();
		var $this = $(this);

		$this.find('.ham').toggleClass('active');
		$('nav.navibar').toggleClass('mobile_show');
	});
	//END

});

if ($(window).scrollTop() + $(window).height() > $(document).height() - 1200) {
	maps();
}

$(window).scroll(function () {
	maps();
});


function maps() {
	if ($('*').is('#map')) {
		if (!YaMapsShown) {
			if ($(window).scrollTop() + $(window).height() > $(document).height() - 1200) {
				YaMapsShown = true;

				DG.then(function () {
					var map, myPopUp0;
					map = DG.map('map', {
						'center': [55.378792, 37.539922],
						'zoom': 15
					});

					myPopUp0 = DG.popup({
						maxWidth: 350,
						sprawling: true
					})
						//координаты метки

						.setLatLng([55.378792, 37.539922])
						//текст метки
						.setContent(`
									<h4 class="map-popup-title">Cтоматологическую клинику «Мята»</h4>
									<p>Подольск, проспект 50-летия Октября, дом 4 "Б"</p>
									<p class="map-popup-paragraph">Телефон: <a href="tel:+79852486308">+7 (985) 248-63-08</a><br><br>
									Режим работы<br><strong>Пн-Сб:</strong> с 9.00 по предварительной записи<br><strong>воскресенье:</strong> с 9.00 по предварительной записи</p>
									<a class="map-popup-btn" target="_blank" href="https://2gis.ru/podolsk/geo/4504235300183931/37.539866%2C55.378779?m=37.540444%2C55.378566%2F20">Открыть на карте</a>
								`).openOn(map);;
					DG.marker([55.378792, 37.539922]).addTo(map).bindPopup(myPopUp0);

				});
				// showYaMaps();
			}
		}
	}
}
$(".stm_mobile__dropdown").click(function (e) {
	e.preventDefault();
	// $(this).parent(".menu-item");
	$(this).parents(".menu-item").toggleClass("show");
	console.log($(this).parents(".menu-item"))
});

$(".custom-mobile-switcher").click(function (e) {
	e.preventDefault();
	$("#header-top").toggleClass("active");
	$(".header-top__overlay").toggleClass("active");
	$("body").toggleClass("no-overflow");
	console.log("dsfs")
});

$(".header-top__overlay").click(function (e) {
	$("#header-top").removeClass("active");
	$(".header-top__overlay").removeClass("active");
	$("body").removeClass("no-overflow");
});
$(".js-mobile-dropdown").click(function (e) {
	e.preventDefault();
	$('.menu-item').removeClass('hover');
	$(this).parents('.menu-item').toggleClass('hover');
});


// $('.reviews-container').slick({
// 	dots: true,
// 	arrows: false,
// 	infinite: true,
// 	speed: 500,
// 	fade: true,
// 	cssEase: 'fade'
// });
// $('.slider-home').slick({
// 	dots: false,
// 	arrows: false,
// 	infinite: true,
// 	speed: 500,
// 	fade: true,
// 	cssEase: 'fade'
// });

// $('.slider-company').slick({
// 	dots: true,
// 	arrows: false,
// 	infinite: true,
// 	autoplay: true,
// 	speed: 500,
// 	fade: true,
// 	cssEase: 'fade'
// });


function showYaMaps() {
	var script = document.createElement("script");
	script.type = "text/javascript";
	script.src = "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Aed95824922d55994b641cc5691082965d69975c9131b89cc0c4cfed8a914a0f6&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;scroll=true";
	document.getElementById("YaMaps").appendChild(script);
}
